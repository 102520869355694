.participantList {
  padding: 5px 15px;
  background-color: #fff;
}

.userProfileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.participantName {
  color: #242c37;
  font-size: 14px;
  font-family: "Montserrat-Bold";
}

.partiDetailsUL {
  display: inline-block;
  list-style-type: none;
  margin: 0px;
  padding-left: 10px;
  vertical-align: top;
}

.timeDetails {
  color: #888c8d;
  font-size: 10px;
  font-family: "Montserrat-Regular";
}

.location {
  color: #888c8d;
  font-size: 12px;
  margin-left: 20px;
  font-family: "Montserrat-Regular";
}

.partiDetailsIcon {
  vertical-align: middle;
  margin-right: 5px;
  font-size: 12px !important;
}
