.guest-header{
    background-color: #8aaf3f;
    height: 60px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;
}
.guest-container{
    /* -webkit-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.75); */
    /* min-height: 100vh; */
}

.round-btn{
    box-shadow: 0.5px 1px 2px 1px rgba(64, 69, 65, 0.75);
    border-radius: 30px;
    height: 50px !important;
}
.guest-header > h5{
    color: #fff;
    padding-left: 30px;
    font-size: 18px;
}
.search{
    background-color:#fff;
    border: 1px solid #ccc !important;
    color: #000;
    height: 40px;
    padding-left: 20px;
    width: unset;
   font-size: 16px;
}

.guest-container > .container{
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
    height: calc(100vh - 60px);   
    justify-content: center;
}

.guest-search-btn{
    margin-top: 20px;
    background-color: #8aaf3f;
    height: 40px;
    border: 0;
    color: #fff;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.guest-search-btn:hover{
    background-color: #6b8536;
    /* font-weight: bold; */
}
button.disabled{
    background-color: grey;
    cursor:not-allowed;
}

button.disabled:hover{
    background-color: grey;
   
}
.not-found{
    text-align: center;
    opacity: 0.6;
    font-size: 14;
}
.btn-info-text{
    font-size: 10px;
    text-align: end;
    opacity: 0.5;
    margin: 0;
    padding-top: 5px;
}