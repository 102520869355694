.img-container {
  width: 100%;
  /* height: 260px; */
  float: left;
  max-width: 100%;
  position: relative;
}

.img-preview {
  /* width: 400px;
  height: 200px;
  max-width: 100%; 
  position: absolute;
  z-index: -1;
  margin: 0px auto;
  left: 0;
  right: 0;
  top: 100px; */
}

.previewOuter {
  width: 260px;
  height: 260px;
  float: left;
  margin-left: 10px;
  background: url("./../../assets/images/frame.png");
  background-size: contain;
  text-align: center;
  position: relative;
}

.sourceImg {
  width: 100%;
  max-height: 260px;
}

.hiddenSection {
  display: none;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}

.uploadBtn {
  border: 2px dashed #a2af8c;
  color: #85ac43;
  background-color: white;
  border-radius: 10px;
  font-size: 11px;
  cursor: pointer;
  width: 255px;
  height: 200px;
  margin: 0px auto;
  text-align: center;
  font-family: "Montserrat-Regular" !important;
}

/* .upload-btn-wrapper input[type="file"] {
  font-size: 300px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
} */

.upload-btn-wrapper input[type="file"] {
  font-size: 300px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.uploadImg {
  width: 30px;
  margin-top: 25%;
  margin-bottom: 5px;
}

.createBtn {
  width: 155px;
  padding: 13px 0px;
  border-radius: 24px;
  background-color: #fff !important;
  box-shadow: 0px 4px 10px #00000029;
  margin-top: 20px !important;
  border: none;
  color: #85ac43 !important;
  font-size: 12px !important;
  font-family: "Montserrat-Bold" !important;
  text-transform: capitalize !important;
}

.createBtnOuter {
  /* text-align: center; */
}

.publishBtn {
  width: 155px;
  padding: 13px 0px;
  border-radius: 24px;
  background-color: #fff !important;
  box-shadow: 0px 4px 10px #00000029;
  margin-top: 20px !important;
  border: none;
  color: #85ac43 !important;
  font-size: 11px !important;
  font-family: "Montserrat-Bold" !important;
  text-transform: capitalize !important;
}

.publishBtnOuter {
  /* text-align: center; */
}

#finalOutputCanvas {
  width: 300px;
  position: relative;
  transform: translate(-50%, 0px);
  left: 50%;
}

.hiddenCanvas {
  visibility: hidden;
}

.shareSectionOuter {
  padding: 30px;
  background-color: #022b4e;
  border-radius: 30px 30px 0px 0px;
  min-height: 120px;
  text-align: center;
  margin: 20px -28px -20px -28px;
}

.shareSectionOuter > p {
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.02px;
  font-family: "Montserrat-Bold" !important;
}

.shareImg {
  max-width: 132px;
  margin-top: 20px;
}

.shareIconsOuterDiv {
  background-color: #fff !important;
  color: #022b4e !important;
}

.fbshareOuter {
  margin-left: 20px !important;
}

.shareBtnSectionOuter {
  margin-top: 25px;
}

.templateBtn {
  background-color: #fff !important;
  color: #85ac43 !important;
  letter-spacing: 0.02px !important;
  font-family: "Montserrat-Bold" !important;

  bottom: 5px !important;
  position: fixed !important;
  border-radius: 10px !important;
  padding: 10px !important;
  font-size: 12px !important;
  width: 80%;
  text-transform: none !important;
  max-width: 435px;
}

.templateCarousel {
  max-width: 300px !important;
  max-height: 300px !important;
  margin: 0px auto;
  padding-bottom: 20px;
}

.templateCarouselImg {
  max-width: 100%;
}

.carousel .slide {
  background: #00000000 !important;
  padding-bottom: 10px !important;
}

.carousel .slide .legend {
  transition: all 0.5s ease-in-out !important;
  position: inherit !important;
  width: 90% !important;
  border-radius: 10px !important;
  background: #0000 !important;
  color: #fff !important;
  padding: 10px !important;
  font-size: 12px !important;
  margin: 10px auto 0px auto !important;
  text-align: center !important;
  /* box-shadow: 1px 2px 5px #ddd !important; */
  border: 1px solid #eee;
}

.carousel .slide img {
  background: url("./../../assets/images/pixel.png") !important;
  background-repeat: repeat;
  background-size: 300px !important;
}

.legend {
  display: none;
}

.text-center {
  text-align: center;
}

.slideNav {
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 30px;
  cursor: pointer !important;
}

.prevSlide {
  left: -10px;
  animation: moveLeftToRight 1s linear infinite;
}

.nextSlide {
  right: -10px;
  animation: moveRightToLeft 1s linear infinite;
}

.control-arrow {
  display: none;
}

.checkboxOuter {
  text-align: center;
  margin-top: 15px;
}

.checkBoxTerms {
  vertical-align: middle;
}

@keyframes moveLeftToRight {
  0%,
  100% {
    left: -10;
  }
  50% {
    left: 0px;
  }
}

@keyframes moveRightToLeft {
  0%,
  100% {
    right: -10;
  }
  50% {
    right: 0px;
  }
}

@media (max-width: 400px) {
  .konvajs-content {
    margin-left: -20px !important;
  }
}
