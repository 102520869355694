.align-center {
  text-align: center;
}

.row {
  margin-bottom: 15px;
  clear: both;
}

nav {
  background-image: url("../../assets/images/customer-register/left-arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 35px;
  float: left;
  margin-right: 3px;
  position: relative;
  left: 0px;
  z-index: 9999;
  height: 18px;
  margin-top: 7px;
}

#page-title {
  font-size: 22px;
  margin-left: 50px;
  font-family: "Montserrat-Bold";
}

.main-container {
  padding: 5px 35px;
  margin-bottom: 70px;
}

div#header {
  margin-bottom: 40px;
}

.sub_title {
  font-size: 16px;
  color: #0a0819c2;
  font-weight: bold;
  padding-bottom: 10px;
  font-family: "Montserrat-Bold";
  margin-bottom: 0px;
}

.full-width {
  width: 100%;
  float: left;
}

section {
  margin: 10px 0px 20px 0px;
}

/*Temporary*/
.btn-done {
  width: 100%;
  background-color: rgba(125, 167, 75, 1) !important;
  color: #fff !important;
  border-radius: 30px !important;
  font-family: "Montserrat-Bold" !important;
  font-size: 14px !important;
  margin-top: 80px !important;
}

.btn-skip {
  width: 100%;
  border-radius: 30px !important;
  font-family: "Montserrat-Bold" !important;
  font-size: 14px !important;
  margin-bottom: 8px !important;
}
/* 
#district-select {
  min-width: 100%;
} */

/* Ticket section */

.ticket-list {
  list-style-type: none;
  margin: 25px 0 0 5px;
  padding: 0;
}

.ticket-list li {
  float: left;
  margin: 0 10px 0 10px;
  width: 80px;
  height: 40px;
  position: relative;
}

.ticket-list label,
.ticket-list input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ticket-list input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
}

.ticket-list input[type="radio"]:checked + label,
.Checked + label {
  background: rgba(125, 167, 75, 1);
  border: 2px solid #ddd;
  color: #fff;
}

.ticket-list label {
  padding: 10px;
  border: 1px solid rgba(125, 167, 75, 1);
  cursor: pointer;
  z-index: 90;
  text-align: center;
  font-family: "Montserrat-Bold";
}

.ticket-list label:hover {
  background: #ddd;
}

.ticketsInfo {
  font-size: 12px;
  color: rgba(125, 167, 75, 1);
  letter-spacing: 0.02px;
  margin-top: 20px;
  font-family: "Montserrat-Medium";
}

.inputSpinnerOuter{
  padding-top: 40px;
}
