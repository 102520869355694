div#goToHomeLink {
  text-align: center;
  text-decoration: underline;
  color: #a7a7b3fc;
  margin-top: 50px;
  margin-bottom: 30px;
}

#content-area {
  min-height: 50vh;
}
.reg-success-image {
  text-align: left;
  display: block;
  /* vertical-align: middle; */
  /* margin: auto 0px; */
  /* padding-top: 120px; */
  width: 100%;
  margin-top: 60px;
}

.regSuccessTxt {
  font-family: "Montserrat-Medium";
  font-size: 22px;
  margin-top: 70px;
  padding: 0px 25px; 
}

.regSuccessTxtInfo {
  font-family: "Montserrat-Medium";
  font-size: 18px;
  padding: 0px 25px;
}

.videoSubInfoMsg {
  font-family: "Montserrat-Regular";
  font-size: 15px;
  padding: 0 25px;
  margin-top: 40px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.successOuter {
  margin-top: -100px;
}

.successContentOuter {
  padding-top: 70px;
}

.successImageOuter {
  padding: 0px 10px;
  /* width: 100%; */
}

.recentParticipantsList{
  margin-top: 50px;
  padding: 0px 10px;
}

.recentPartHead{
  font-family: "Montserrat-Medium";
  font-size: 18px;
  padding: 0px 15px;
  width: 65%;
}

.recentPartHeadOuter{
  display: flex;
  align-items: center;
}

.viewAllParticipantPageLink{
  font-family: "Montserrat-Medium";
  font-size: 15px;
  color: rgb(27, 39, 212);
}