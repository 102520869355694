.participantSingleCard{
    border: 2px solid #c5babafa;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 30px;
    box-shadow: 2px 2px 4px #d1cbcb;
}

.participantVideoIframe{
    max-width: 100%;
    border-radius: 5px;
    height: 200px;
}

.participantName{
    width: 80%;
    font-size: 18px !important;  
    font-family: "Montserrat-Bold" !important;
}

.participantAge{
    width: 20%;
    text-align: right;
    font-size: 18px !important;  
    font-family: "Montserrat-Medium" !important;
}

.participantInfo{
    display: flex;
}

.participantSchool{
    width: 100%;    
    font-size: 15px !important;  
    font-family: "Montserrat-Medium" !important;
}