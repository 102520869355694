.privacyGreenBlock {
  background-image: linear-gradient(to right, #9dbb2c, #7da74b);
  padding: 20px;
}

.ppOuter {
  padding: 25px;
  background-color: rgba(255, 255, 255, 0.144);
  border: 0.5px solid rgba(255, 255, 255, 0.144);
  border-radius: 8px;
}

.ppTitle {
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.03px;
  margin: 0px;
}

span#username {
  font-size: 1.5em;
}

.headerTitle {
  text-align: center;
  font-size: 17px;
  color: #fff;
  margin-top: 0px;
  font-family: "Montserrat-Bold";
}

.pageOuter {
  min-height: 100vh;
  background-color: #f6f6f6;
}

.pp-content {
  padding: 5px 40px;
  text-align: justify;
}

.pp-content-outer {
  /* max-height: 740px !important; */
  overflow-y: scroll !important;
  min-height: 50vh;
  padding-bottom: 100px !important;
  overflow-x: hidden;
}

.contentExtremeOuter {
  position: relative;
}

.searchFormOuter {
  /* border-radius: 25px;
  background-color: rgba(184, 233, 148, 0.5);
  border: 2px solid #fff; */
  padding: 5px;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  margin-top: 100px;
  padding: 25px;
}

.searchBidInput {
  /* width: 80%; */
  width: 100% !important;
  border: 2px solid #aaa !important;
  border-radius: 4px !important;
  margin: 8px 0 !important;
  outline: none !important;
  padding: 8px !important;
  box-sizing: border-box !important;
  transition: 0.3s !important;
  font-family: "Montserrat-Regular" !important;
  height: 50px !important;
  font-size: 16px !important;
}

.searchBidInput:focus {
  border-color: #cde479 !important;
  box-shadow: 0 0 8px 0 #cde479 !important;
}

.searchBtn {
  border-radius: 4px;
  background-color: #7da74b;
  width: 100%;
  padding: 10px 0px;
  border: none;
  outline: none;
  font-family: "Montserrat-SemiBold";
  color: #fff;
  font-size: 20px;
  height: 50px;
  margin-top: 10px;
}

.searchLabel {
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
  text-align: left;
}

.search-container {
  float: right;
  border: 1px solid #000;
}

.search-container > input[type="text"] {
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
}

.search-container button {
  float: right;
  padding: 6px 10px;
  margin-top: 8px;
  margin-right: 16px;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.search-container button:hover {
  background: #ccc;
}

.inlineSearchInput {
  width: 68%;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none !important;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  font-family: "Montserrat-Regular";
  font-size: 16px;
}

.inlineSearchBtn {
  background-color: #a9c34b;
  width: 15%;
  border: none;
  outline: none;
  font-family: "Montserrat-Bold";
  color: #fff;
  font-size: 15px;
  vertical-align: middle;
  height: 35px;
  border-radius: 4px;
  margin-left: 3px;
}

.inlineSearchOuter {
  vertical-align: middle;
  justify-content: center;
}

.searchIcon {
  font-size: 20px;
  font-family: "Montserrat-Bold";
  vertical-align: middle;
}

.buttonLeftIcon {
  margin-right: 5px;
}

.disabledStyle {
  opacity: 0.7;
}

.inlineSearchBtnMain {
  background-color: #7da74b;
  width: 18%;
  border: none;
  outline: none;
  font-family: "Montserrat-Bold";
  color: #fff;
  font-size: 15px;
  vertical-align: middle;
  height: 50px;
  border-radius: 4px;
  margin-left: 3px;
}

.loadBtn {
  color: #fff !important;
  background-color: #7da74b !important;
  font-size: 11px !important ;
  font-family: "Montserrat-Bold" !important;
  letter-spacing: 0.02px !important;
  text-transform: capitalize;
  width: 150px;
}

.loadBtnOuter {
  text-align: center;
  padding-bottom: 10px;
  margin-top: 10px;
}

.pleaseWaitTxt {
  color: #7da74b;
  font-size: 11px;
  font-family: "Montserrat-Bold";
  letter-spacing: 0.02px;
  text-align: center;
  margin-top: 5px;
}

.photosLoaderImg {
  width: 60px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  vertical-align: middle;
  margin: 0 auto;
  /* margin-top: 20vh; */
}

.veloLoaderText {
  color: #7da74b;
  font-size: 11px;
  font-family: "Montserrat-Bold";
  letter-spacing: 0.02px;
  text-align: center;
  margin-top: 10px;
}

.veloLoaderOuter {
  text-align: center;
  vertical-align: super;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.eventSearchField > .MuiOutlinedInput-root {
  border-radius: 0px !important;
  background-color: #fff;
}

.inputOuter {
  height: 80px;
}

.searchInputOuter > .MuiAutocomplete-root {
  width: 100% !important;
}

.showEventSearch {
  font-size: 30px;
  position: absolute;
  top: 80px;
  font-weight: bold;
  left: 34px;
}
.selectBtn{
  /* width: 45%; */
  margin-top: 20px;
  padding:10px;
  border-radius: 20px;
  border-width: 0px;
  background-color: #7da74b;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
}
.imageWraper{
  margin-top: 20px;
  margin-bottom: 30px;
}
.labelText{
  font-size: 16px;
  font-weight: bold;
}