.image-upload-wrapper{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.imageupload-btn{
    display: flex;
    justify-content: center;
}
.upload-info-wrapper ol{
    padding-top: 20px;
    font-size: 14px;
    opacity: 0.5;
}
.page-out-wrapper{
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
}
.disabled-btn{
    background-color: grey;
}
.checkbox-form{
    display: flex;
    align-items: center;
    font-size: 14px;
}
.checkbox-form>svg{
    color: #000;
}
.checkbox-form>p{
    color: #000;
    padding-right: 5px;

}
.info-alert{
    padding-left: 30px;
    padding-right: 30px;
}
.info-title{
    font-size: 16px;
    font-weight: bold;    
}
.info-alert{
    font-size: 14px;
}

.innerPadding{
    padding-left: 20px;
    padding-right: 20px;
}
.cBtnWraper>button{
    border-radius: 5px;
}