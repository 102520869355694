#landing_container {
  width: 100%;
  min-height: 100vh;
  background-image: url("../../assets/images/landing/landing_bg.svg");
  position: absolute;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
#logo_section {
  width: 135px;
  height: 135px;
  position: absolute;
  z-index: 1;
  top: 12%;
  transform: translate(-50%, 0px);
  left: 50%;
  background-color: #fff;
  border-radius: 50%;
}

#loader_section {
  position: absolute;
  bottom: 10%;
  /* background-color: #96b733; */
  width: 50px;
  height: 50px;
  left: 50%;
  transform: translate(-50%, 0px);
}

.fullWidthImage {
  width: 100%;
}

#loading {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
