.MuiOutlinedInput-root {
  border-radius: 30px !important;
  background-color: rgba(239, 239, 239, 1);
}

.align-center {
  text-align: center;
}

.row {
  margin-bottom: 15px;
  clear: both;
}

nav {
  background-image: url("../../assets/images/customer-register/left-arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 35px;
  float: left;
  margin-right: 3px;
  position: relative;
  left: 0px;
  z-index: 9999;
  height: 18px;
  margin-top: 7px;
}

#page-title {
  font-size: 22px;
  margin-left: 50px;
  font-family: "Montserrat-Bold";
}

.main-container {
  padding: 5px 35px;
  margin-bottom: 70px;
}

div#header {
  margin-bottom: 40px;
}

.sub_title_form {
  font-size: 16px;
  color: #0a0819;
  font-weight: bold;
  padding-bottom: 10px;
  font-family: "Montserrat-Bold";
  margin-bottom: 8px !important;
}
.gender_icon {
  width: 50px;
  height: 50px;
}

#gender_men {
  background-image: url("../../assets/images/customer-register/man.svg");
  background-repeat: no-repeat;
  margin-left: 30px;
}

#gender_men.selected {
  background-image: url("../../assets/images/customer-register/man-selected.svg");
  background-repeat: no-repeat;
  margin-left: 30px;
}
#gender_women {
  background-image: url("../../assets/images/customer-register/woman.svg");
  background-repeat: no-repeat;
}
#gender_other {
  background-image: url("../../assets/images/customer-register/man.svg");
  background-repeat: no-repeat;
  border-right: none;
}

.gender_icon {
  float: left;
  border-right: 1px solid #00000042;
  margin: 0px 12px;
  padding: 0px 8px;
  background-size: contain;
}

.full-width {
  width: 100%;
  float: left;
}

div.blood-group-icon {
  float: left;
  width: 40px;
  height: 40px;
  border: 2px solid #c9c9c9;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 10px;
}
.blood-group-icon span {
  position: relative;
  top: 18%;
  left: 29%;
  font-size: 13px;
  color: #a2a2a2;
}

section {
  margin: 10px 0px 20px 0px;
}

.blood-group-icon.selected {
  background-color: rgba(157, 187, 44, 1);
}

.blood-group-icon.selected span {
  color: #fff;
  font-family: "Montserrat-Regular" !important;
  font-size: 14px;
}

.MuiFormLabel-root {
  font-family: "Montserrat-Regular" !important;
  font-size: 12px;
}

/*Temporary*/
.btn-next {
  width: 45%;
  background-color: rgba(125, 167, 75, 1) !important;
  color: #fff !important;
  border-radius: 30px !important;
  font-family: "Montserrat-Bold" !important;
  font-size: 14px !important;
}

.btn-prev {
  width: 45%;
  border-radius: 30px !important;
  font-family: "Montserrat-Bold" !important;
  font-size: 14px !important;
  right: 10px;
  /* margin-bottom: 5px !important; */
}
.btn-prev-disabled {
  width: 45%;
  border-radius: 30px !important;
  font-family: "Montserrat-Bold" !important;
  font-size: 14px !important;
  right: 10px;
  opacity: 0.5;
  /* margin-bottom: 5px !important; */
}
/* 
#district-select {
  min-width: 100%;
} */

.datePicker {
  width: 96%;
  border-radius: 30px !important;
  background-color: rgba(239, 239, 239, 1);
  line-height: 1.1876em;
  border: 1px solid #b8b8b8;
  height: 50px;
  padding-left: 10px;
  font-size: 15px;
}

#footerBtnSection {
  text-align: center;
}

.MuiSelect-select.MuiSelect-select {
  text-align: left;
}

.react-datepicker-popper[data-placement^="bottom"] {
  z-index: 9;
}
