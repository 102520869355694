/* Loader */

.customLoaderOuter {
  width: 255px;
  height: 200px;
  background-color: #fff;
  text-align: center;
  border: 2px dashed #a2af8c;
  border-radius: 10px;
  margin: 0px auto;
  margin-top: 32px;
}

.customLoaderImg {
  width: 80px;
  margin-top: 30px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.customLoaderTxt {
  font-size: 12px;
  color: #7da74b;
  letter-spacing: 0.02px;
  margin-top: 0px;
  font-family: "Montserrat-Bold" !important;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
