.backBtn {
  color: #fff;
  position: absolute;
  font-size: 25px;
  cursor: pointer;
}

.headerRegisteredOuter {
  position: relative;
  padding: 20px;
}

.headerRegisteredTitle {
  text-align: center;
  font-size: 17px;
  color: #fff;
  margin-top: 0px;
  font-family: "Montserrat-Bold";
}

.postOuter {
  min-height: 100vh;
  background-image: linear-gradient(to right, #9dbb2c, #7da74b);
  padding-bottom: 50px !important;
}

.eventRegisteredOuter {
  padding: 25px;
  background-color: rgba(255, 255, 255, 0.144);
  border: 0.5px solid rgba(255, 255, 255, 0.144);
  border-radius: 8px;
  min-height: max-content;
  margin-left: 30px;
  margin-right: 30px;
}

.eventRegisteredTitle {
  font-size: 15px;
  color: #fff;
  letter-spacing: 0.03px;
  margin: 0 auto;
  font-family: "Montserrat-SemiBold";
}

.eventRegisteredDetails {
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.02px;
  margin-top: 10px;
  font-family: "Montserrat-Regular";
}

.createPostSection {
  /* padding: 20px; */
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.createPostLabel {
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.02px;
  font-family: "Montserrat-Regular";
}

.hiddenSection {
  display: none;
}

.clearBoth {
  clear: both !important;
}

.btnAndInfoSection {
  margin-top: 15px;
  text-align: center;
}

.btnUploadVideo {
  width: 45%;
  background-color: rgb(105, 117, 223) !important;
  color: #fff !important;
  border-radius: 30px !important;
  font-family: "Montserrat-Bold" !important;
  font-size: 14px !important;
}

p.file-upload-notice {
  font-size: 12px;
  text-align: left;
  font-family: "Montserrat-Regular" !important;
  color: #b77019cf;
  background-color: rgba(255, 255, 255, 0.144);
  border: 0.5 px solid rgba(255, 255, 255, 0.144) !important;
  padding: 10px 20px 10px 20px;
  width: auto !important;
}
.videoPlayer {
  background-color: #000;
}

.fileUploadLoaderImg {
  width: 75px;
  margin-top: 1px !important;
}
