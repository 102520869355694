.name {
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
}
.age {
  font-size: 16px;
  font-weight: 400;
}
.phone {
  margin: 10px 0px;
}
.district {
  text-align: center;
  margin: 10px 0px;
  font-size: 12px;
}

.section {
  margin: 10px 0px;
}

.label {
  font-size: 12px;
  font-weight: 500;
}
.value {
  font-size: 15px;
  font-weight: 600;
}

.MuiCard-root {
  overflow: hidden;
  margin-bottom: 20px;
  /* background-image: linear-gradient(
    140deg,
    #ffffff 0%,
    #fffffbcf 50%,
    #9dbb2c 75%
  ); */

  background-image: linear-gradient(
    140deg,
    #ffffff 0%,
    #9dbb2c00 50%,
    #e1e3da 75%
  );
}

.card-participants {
  margin-bottom: 50px;
}

.payment-summary {
  margin-top: 30px;
  /* margin-bottom: 20px; */
  /* padding-bottom: 20px; */
  border-top: 1px solid #0000002e;
  /* border-bottom: 1px solid #0000002e; */
}

.payment-summary .row {
  padding-bottom: 15px;
}

.payment-summary .label {
  width: 50%;
  font-size: 15px;
  float: left;
  text-align: left;
}

.payment-summary .value {
  width: 45px;
  font-size: 15px;
  float: right;
  text-align: left;
  padding-left: 30px;
}

.MuiChip-root.chip-video-submitted {
  position: absolute;
  top: 20px;
  height: 20px;
  right: 0;
  font-size: 11px;
  color: #fff;
  background-color: #85ac43;
  letter-spacing: 0.6px;
  width: 90px;
}
.MuiChip-root.chip-video-pending {
  position: absolute;
  top: 20px;
  height: 20px;
  right: 0;
  font-size: 11px;
  color: #fff;
  background-color: #39f;
  letter-spacing: 0.6px;
  width: 90px;
}
