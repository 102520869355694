.headerGreenBlock {
  background-image: linear-gradient(to right, #9dbb2c, #7da74b);
  padding: 30px;
}

.campWallTitle {
  text-align: center;
  font-size: 17px;
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Montserrat-Bold";
}

.campWallTeam {
  font-size: 13px;
  padding: 0;
  margin: 8px;
  text-align: center;
  color: #fff;
  font-family: "Montserrat-Medium";
}
