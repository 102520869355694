@font-face {
  font-family: 'Montserrat-Medium'; /* Choose a name for your font */
  src: url('assets/fonts/montserrat/Montserrat-Medium.ttf') format('opentype');
  /* Add other font formats if necessary for cross-browser compatibility */
}

@font-face {
  font-family: 'Poppins-Medium'; /* Choose a name for your font */
  src: url('assets/fonts/poppins/Poppins-Bold.ttf') format('opentype');
  /* Add other font formats if necessary for cross-browser compatibility */
}

.PoppinsMedium{
  font-family: 'Poppins-Medium', sans-serif;
}

.TiltilumMedium{
  font-family: 'SpaceGrotesk-Regular', sans-serif;
}
.MontserratMedium{
  font-family: 'Montserrat-Medium', sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.width50 {
  width: 50%;
}

.width30 {
  width: 30%;
}

.width70 {
  width: 70%;
}

.width40 {
  width: 40%;
}

.width60 {
  width: 60%;
}

.width100 {
  width: 100%;
  max-width: 440px;
}

.width55 {
  width: 55%;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.floatLeft {
  float: left;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.selected-image::after{
   /* width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999; */
}
.checkbox-container {
  border-radius: 50%;
  padding: 5px;
  height: 10px;
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer{
  font-size: 12px;
  text-align: center;
  background-image: linear-gradient(to right, #9dbb2c, #7da74b);
  position: fixed;
  width: 500px;
  font-weight: 600;
  bottom: 0;
  /* color: #ffff; */
}

.searchBtn{
  font-size: 14px !important;
}

@media screen and (max-width: 500px) {
  .footer{
    width: 100%;
  }
}