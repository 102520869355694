.expiredPageOuter {
  min-height: 100vh;
  /* background-image: linear-gradient(to right, #9dbb2c, #7da74b); */
  background-color: #fdfdfe;
  text-align: center;
}

.expiredImg {
  width: 85%;
  margin-top: 15%;
  margin-bottom: 60px;
  max-width: 250px;
}

.expiredMsgTitle {
  color: #000;
  font-size: 22px;
  margin-top: 5px;
  margin-bottom: 15px;
  font-family: "Montserrat-Bold";
}

.expiredMsg {
  color: #000;
  font-size: 18px;
  margin-top: 0px;
  font-family: "Montserrat-SemiBold";
}
