.loginOuter {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: #fff;
}

.logoOuter {
  width: 145px;
  height: 145px;
  position: absolute;
  z-index: 1;
  top: 25%;
  transform: translate(-50%, 0px);
  left: 50%;
  border-radius: 50%;
  background-size: 130px;
  background-repeat: no-repeat;
  background-position: center;
}

.logoMain {
  width: 100%;
  height: 50vh;
  position: absolute;
  background-image: url("../../assets/images/login/bg.svg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

img {
  /* width: 100%; */
}

.contentMainOuter {
  width: 100%;
  height: 40vh;
  position: absolute;
  bottom: 20%;
  text-align: center;
}

.contentHead {
  width: 100%;
  background-image: url("../../assets/images/login/contentHeadBg.svg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding: 20px 0px;
}

.contentHead > h1 {
  font-size: 18px;
  font-family: "Montserrat-Bold";
}

.socialBtn {
  width: 300px;
  border-radius: 33px;
  padding: 13px 0px;
  /* text-align: left; */
  text-align: center;
  position: relative;
  outline: none !important;
}

.fbBtn {
  background-color: #2d509b;
  color: #fff;
  border: none;
}

.googleBtn {
  border: 1px solid #c9c9c9;
  background-color: #fff;
  color: #000000;
  margin-top: 20px;
}

.socialIcon {
  max-width: 14px;
  /* margin-left: 40px; */
  position: absolute;
  left: 40px;
}

.socialBtn > span {
  /* font-size: 15px; */
  /* margin-left: 60px; */
  vertical-align: super;
  /* font-family: "Montserrat-Regular"; */
}

.socialBtnOuter {
  padding-top: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.appLogo {
  margin-top: 2px;
  width: 100%;
}
.login-btn{
  padding: 20px;
  width: 300px;
  border: 1px solid #c9c9c9;
  background-color: #fff;
  color: #000000;
  margin-top: 20px;
  font-size: bold;
  width: 300px;
  border-radius: 33px;
  padding: 13px 0px;
  text-align: left;
  text-align: center;
  position: relative;
  outline: none !important;
}

.btn-icon{
  position: absolute;
  position: absolute;
  bottom: 15%;
  z-index: 999;
  padding-left: 35px;
}
.loginOuter > button{
  font-size: 14px;
  font-weight: 500;
  /* opacity: 0.5; */
  -webkit-box-shadow: 0px 0px 21px -13px rgba(0,0,0,0.73);
  -moz-box-shadow: 0px 0px 21px -13px rgba(0,0,0,0.73);
  box-shadow: 0px 0px 21px -13px rgba(0,0,0,0.73);
}

.loginOuter > button:hover{
  background-color: #8aaf3f;
  color: #fff;
  font-weight: bold;
}